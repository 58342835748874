import React, { useState, useEffect } from 'react';
import { AppBar, Typography, Toolbar, Tab, Tabs, IconButton, Box, Paper, Popover } from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate, useLocation } from 'react-router-dom';
import Carrinho from '../../pages/carrinho/Carrinho';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (location.pathname === '/') {
      setValue(0);
    } else if (location.pathname === '/contatos') {
      setValue(1);
    }
  }, [location.pathname]);

  const handleNavigate = (newValue) => {
    setValue(newValue);

    if (newValue === 1) {
      navigate("/");
    } else if (newValue === 2) {
      navigate('/contatos');
    }
  };

  const handleCartClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCart = () => {
    setAnchorEl(null);
  };

  const isCartOpen = Boolean(anchorEl);
  
  return (
      <AppBar position='absolute' sx={{ background: "purple" }}>
        <Toolbar>
          <Typography onClick={() => handleNavigate(1)} style={{cursor: 'pointer'}}>
            AÇAIMANIA
          </Typography>
          <Tabs
            sx={{ marginLeft: 'auto' }}
            textColor="inherit"
            indicatorColor="yellow"
            value={value}
            onChange={(event, newValue) => handleNavigate(newValue)}
          >
          </Tabs>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={handleCartClick} color="inherit">
              <ShoppingCartIcon />
            </IconButton>
            <Popover
              open={isCartOpen}
              anchorEl={anchorEl}
              onClose={handleCloseCart}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Paper>
                <Box p={2}>
                  <Carrinho />
                </Box>
              </Paper>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
  );
};

export default Header;
