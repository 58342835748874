import { useEffect, useState } from 'react';
import {
	Button,
	Container,
	Grid,
	IconButton,
	Typography,
	Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Acai from '../menu/acai.jpg';
import AcaiP from '../menu/acaip.jpg';
import Tigela from '../menu/tigela.jpg';
import Cookies from 'js-cookie';
import CryptoJs from 'crypto-js';

const Carrinho = () => {
	const style = {
		py: 0,
		maxWidth: 800,
		borderRadius: 2,
		border: '1px solid',
		borderColor: 'divider',
		marginBottom: '1.2rem',
	};

	const navigate = useNavigate();

	const [carrinho, setCarrinho] = useState([]);

	useEffect(() => {
		const carrinhoSalvo = Cookies.get('pedidos');
		if (carrinhoSalvo) {
			const decryptedCarrinho = CryptoJs.AES.decrypt(
				carrinhoSalvo,
				'chave-pedidos-acaimania'
			).toString(CryptoJs.enc.Utf8);

			setCarrinho(JSON.parse(decryptedCarrinho));
		}
	}, []);

	const calcularTotal = () => {
		return carrinho
			.reduce((total, item) => total + item.valorPedido, 0)
			.toFixed(2);
	};

	const handleDeleteItem = (index) => {
		const novoCarrinho = [...carrinho];
		novoCarrinho.splice(index, 1);
		setCarrinho(novoCarrinho);
		const encryptedCarrinho = CryptoJs.AES.encrypt(
			JSON.stringify(novoCarrinho),
			'chave-pedidos-acaimania'
		).toString();
		Cookies.set('pedidos', encryptedCarrinho, {
			expires: 7,
		});

		if (novoCarrinho.length === 0) {
			Cookies.remove('pedidos');
			navigate('/');
		}
	};

	return (
		<Container sx={{ marginBottom: '5rem' }}>
			<Grid
				container
				marginBottom={2}
				maxWidth='auto'
			>
				<Grid
					item
					xs={12}
					sm={20}
				>
					<Typography
						color='purple'
						variant='h5'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '1rem',
							marginTop: '1.2rem',
						}}
					>
						Carrinho
					</Typography>
				</Grid>
				<Grid
					item
					xs={10}
					sm={8}
					textAlign='right'
				></Grid>
			</Grid>
			{carrinho.length === 0 ? (
				<Typography>Não há pedidos :(</Typography>
			) : (
				<>
					{carrinho.map((item, index) => (
						<Grid
							container
							spacing={1}
							key={item}
							sx={style}
							marginBottom='1rem'
							xs={12}
							sm={20}
						>
							<Grid
								item
								xs={5}
								sm={1}
							>
								{item.tamanhoAcai === '250ml' ? (
									<img
										src={AcaiP}
										alt='açai 250ml'
										height='110'
										maxWidth='auto'
									/>
								) : item.tamanhoAcai === '300ml' ? (
									<img
										src={Tigela}
										alt='açai 300ml'
										height='110'
										maxWidth='120'
									/>
								) : (
									<img
										src={Acai}
										alt='açai 550ml'
										height='110'
										maxWidth='100'
									/>
								)}
							</Grid>
							<Grid
								item
								xs={5}
								sm={10}
							>
								<Typography
									variant='body2'
									marginTop={2}
									marginLeft='5rem'
									sx={{ display: 'flex-start' }}
								>
									Açaí: {item.tamanhoAcai}
								</Typography>
								<Typography
									variant='body2'
									marginTop={1}
									marginLeft='5rem'
									sx={{ display: 'flex-start' }}
								>
									Adicionais: {item.adicionais}
								</Typography>
							</Grid>
							<Grid
								item
								xs={6}
								sm={2}
							>
								<Typography variant='body2'>
									Preço: R${item.valorPedido.toFixed(2)}
								</Typography>
							</Grid>
							<Grid
								item
								sm={10.5}
								xs={11}
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
								}}
							>
								<IconButton
									sx={{ marginTop: '0.1rem' }}
									onClick={() => handleDeleteItem(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					))}
					<Typography sx={{ float: 'left' }}>
						Total:
						<Typography
							item
							xs={1}
							fontWeight='bold'
						>
							R${calcularTotal()}
						</Typography>
					</Typography>
					<Link href='/#/entrega'>
						<Button
							sx={{ float: 'right', marginTop: '0.3rem' }}
							variant='contained'
							color='secondary'
						>
							Comprar
						</Button>
					</Link>
				</>
			)}
		</Container>
	);
};
export default Carrinho;
