import React from 'react';
import Header from '../../components/Header/Header';
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Contatos() {
	const instagramURL = 'https://www.instagram.com/maniaacaidelivery/';

	const handleInstagramClick = () => {
		window.open(instagramURL, '_blank');
	};

	return (
		<>
			<Header />
			<Grid
				container
				spacing={2}
				marginTop='5rem'
			>
				<Container
					maxWidth='sm'
					component={Paper}
					elevation={4}
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						height: '50vh', // Ajuste a altura conforme necessário
					}}
				>
					<Typography
						marginBottom='2rem'
						variant='h5'
						fontWeight='bold'
					>
						Entre em contato!
					</Typography>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginBottom: '1rem',
						}}
					>
						<LocationOnOutlinedIcon color='secondary' />
						<Typography
							marginLeft='0.5rem'
							variant='body1'
							fontWeight='bold'
						>
							{' '}
							Avenida Vaz Ferreira, 1327 - Centro, Tupanciretã
						</Typography>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginTop: '1rem',
							marginBottom: '1rem',
						}}
					>
						<LocalPhoneOutlinedIcon color='secondary' />
						<Typography
							marginLeft='0.5rem'
							variant='body1'
							fontWeight='bold'
						>
							{' '}
							(55) 99941-4320
						</Typography>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginTop: '1rem',
							marginBottom: '1rem',
						}}
					>
						<IconButton
							onClick={handleInstagramClick}
							color='secondary'
							style={{ padding: 0 }}
						>
							<InstagramIcon />
						</IconButton>
						<Typography
							onClick={handleInstagramClick}
							marginLeft='0.5rem'
							variant='body1'
							fontWeight='bold'
						>
							maniaacaidelivery
						</Typography>
					</div>
				</Container>
			</Grid>
		</>
	);
}
