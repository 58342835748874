import React from "react";
import Header from "../../components/Header/Header";
import Carrinho from "../carrinho/Carrinho";
import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CarrinhoPage() {
  const navigate = useNavigate();

  const handleNavigateClick = () => {
    navigate("/");
  };

  return (
    <>
      <Header />
      <Box sx={{ marginTop: "3rem" }}>
        <Carrinho />
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleNavigateClick}
          >
            Adicionar mais
          </Button>
        </Grid>
      </Box>
    </>
  );
}
