import React from "react";
import Header from "../../components/Header/Header";
import {
  Container,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { useNavigate } from "react-router-dom";

export default function Finalizado() {
  const instagramURL = "https://www.instagram.com/maniaacaidelivery/";
  const waMeURL =
    "https://api.whatsapp.com/send?phone=5555999414320&text=Olá%2C%20gostaria%20de%20fazer%20um%20pedido!";
  const pixKey = "34.371.539/0001-79";
  const navigate = useNavigate();

  const handleInstagramClick = () => {
    window.open(instagramURL, "_blank");
  };

  const handleWhatsappClick = () => {
    window.open(waMeURL, "_blank");
  };
  const handleNavigateClick = () => {
    navigate("/");
  };
  const handlePixKeyClick = () => {
    if (document.hasFocus()) {
      navigator.clipboard
        .writeText(pixKey)
        .then(() => {
          alert("Chave PIX copiada para a área de transferência!");
        })
        .catch((error) => {
          console.error("Erro ao copiar a chave PIX: ", error);
        });
    } else {
      console.warn(
        "A página não está focada. A cópia para a área de transferência não foi realizada."
      );
    }
  };
  return (
    <>
      <Header />
      <Grid container spacing={2} sx={{ marginTop: "5rem" }}>
        <Container
          maxWidth="sm"
          component={Paper}
          elevation={4}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "60vh",
          }}
        >
          <Typography marginTop="1rem" variant="h5" fontWeight="bold">
            Pedido Finalizado!
          </Typography>
          <Typography marginBottom="1rem" variant="h6" fontWeight="bold">
            Seu pedido será entregue entre 30 minutos a 1 hora!
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <LocationOnOutlinedIcon color="secondary" />
            <Typography marginLeft="0.5rem" variant="body1" fontWeight="bold">
              {" "}
              Avenida Vaz Ferreira, 1327 - Centro, Tupanciretã
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <LocalPhoneOutlinedIcon color="secondary" />
            <a
              onClick={handleWhatsappClick}
              style={{
                textDecoration: "none",
                color: "secondary",
                cursor: "pointer",
              }}
            >
              <Typography marginLeft="0.5rem" variant="body1" fontWeight="bold">
                Entre em contato!
              </Typography>
            </a>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <IconButton
              onClick={handleInstagramClick}
              color="secondary"
              style={{ padding: 0, cursor: "pointer" }}
            >
              <InstagramIcon />
            </IconButton>
            <Typography
              onClick={handleInstagramClick}
              marginLeft="0.5rem"
              variant="body1"
              fontWeight="bold"
              style={{ cursor: "pointer" }}
            >
              maniaacaidelivery
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <KeyOutlinedIcon color="secondary" />
            <Typography
              onClick={handlePixKeyClick}
              marginLeft="0.5rem"
              variant="body1"
              fontWeight="bold"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Clique para copiar a chave pix (CNPJ)
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNavigateClick}
            >
              Pedir novamente?
            </Button>
          </div>
        </Container>
      </Grid>
    </>
  );
}
