import { HashRouter, Routes, Route } from 'react-router-dom';
import Menu from '../menu/Menu';
import Endereco from '../endereco/Endereco';
import Carrinho from '../CarrinhoPage/CarrinhoPage';
import Contatos from '../contatos/Contatos';
import Adicionais from '../adicionais/adicionais';
import Finalizado from '../confirmacao/Finalizado';

const Router = () => {
	return (
		<HashRouter>
			<Routes>
				<Route
					path='/'
					element={<Menu />}
				/>
				<Route
					path='/entrega'
					element={<Endereco />}
				/>
				<Route
					path='/carrinho'
					element={<Carrinho />}
				/>
				<Route
					path='/contatos'
					element={<Contatos />}
				/>
				<Route
					path='/adicionais'
					element={<Adicionais />}
				/>
				<Route
					path='/finalizado'
					element={<Finalizado/>}
				/>
			</Routes>
		</HashRouter>
	);
};

export default Router;
