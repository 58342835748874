import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  FormControlLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import Header from "../../components/Header/Header";
import * as yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import CryptoJs from "crypto-js";

const server = process.env.SERVER_HOST
  ? process.env.SERVER_HOST
  : "https://acaimaniadelivery.com:3001";

const yupObj = yup.object({
  nomeCliente: yup.string().required("Nome necessário."),
  numeroTelefone: yup.string().required("Número de telefone necessário"),
  rua: yup.string().required("Rua necessária."),
  numero: yup.string().required("Número da casa necessário."),
  bairro: yup.string().required("Bairro necessário."),
  pontoReferencia: yup.string().required("Ponto de referência necessário."),
  formaPagamento: yup.string().required("Forma de pagamento necessária"),
  observacoes: yup.string(),
});

const yupObj2 = yup.object({
  nomeCliente: yup.string().required("Nome necessário."),
  numeroTelefone: yup.string().required("Número de telefone necessário"),
  rua: yup.string(),
  numero: yup.string(),
  bairro: yup.string(),
  pontoReferencia: yup.string(),
  formaPagamento: yup.string().required("Forma de pagamento necessária"),
  observacoes: yup.string(),
});

const Endereco = () => {
  const [isTele, setIsTele] = useState(true);
  const [showAddressAndPayment, setShowAddressAndPayment] = useState(false);
  const [carrinho, setCarrinho] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [mensagemSnackbar, setMensagemSnackbar] = useState("");
  const [severitySnackbar, setSeveritySnackbar] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const carrinhoSalvo = Cookies.get("pedidos");
    if (carrinhoSalvo) {
      const decryptedCarrinho = CryptoJs.AES.decrypt(
        carrinhoSalvo,
        "chave-pedidos-acaimania"
      ).toString(CryptoJs.enc.Utf8);

      setCarrinho(JSON.parse(decryptedCarrinho));
    } else {
      navigate("/finalizado");
    }
  }, [navigate]);

  const calcularTotal = () => {
    if (!showAddressAndPayment) {
      return carrinho
        .reduce((total, item) => total + item.valorPedido, 0 + 10)
        .toFixed(2);
    } else {
      return carrinho
        .reduce((total, item) => total + item.valorPedido, 0)
        .toFixed(2);
    }
  };

  const toggleForm = () => {
    setIsTele(!isTele);
    setShowAddressAndPayment(isTele);
  };

  const validationSchema = !showAddressAndPayment ? yupObj : yupObj2;

  const formik = useFormik({
    initialValues: {
      nomeCliente: "",
      numeroTelefone: "",
      rua: "",
      numero: "",
      bairro: "",
      pontoReferencia: "",
      formaPagamento: "",
      observacoes: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(carrinho);
      await axios.post(`${server}/clientes`, {
        cliente: {
          nomeCliente: formik.values.nomeCliente,
          numeroTelefone: formik.values.numeroTelefone,
          tipoEntrega: !showAddressAndPayment ? "Entrega" : "Retirar",
          tipoPagamento: formik.values.formaPagamento,
          bairroCliente: !showAddressAndPayment ? formik.values.bairro : "",
          ruaCliente: !showAddressAndPayment ? formik.values.rua : "",
          numeroResidencia: !showAddressAndPayment ? formik.values.numero : "",
          pontoReferencia: !showAddressAndPayment
            ? formik.values.pontoReferencia
            : "",
          observacoes: formik.values.observacoes,
          valorTotal: calcularTotal(),
        },
        pedidos: carrinho,
      });
      /*carrinho.map(
				async (item) =>
					await axios.post(`${server}/clientes`, {
						nomeCliente: formik.values.nomeCliente,
						numeroTelefone: formik.values.numeroTelefone,
						tamanhoAcai: item.tamanhoAcai,
						adicionais: item.adicionais,
						tipoEntrega: !showAddressAndPayment
							? 'Entrega'
							: 'Retirar',
						tipoPagamento: formik.values.formaPagamento,
						bairroCliente: !showAddressAndPayment
							? formik.values.bairro
							: '',
						ruaCliente: !showAddressAndPayment
							? formik.values.rua
							: '',
						numeroResidencia: !showAddressAndPayment
							? formik.values.numero
							: '',
						pontoReferencia: !showAddressAndPayment
							? formik.values.pontoReferencia
							: '',
						valorPedido: item.valor,
						observacoes: formik.values.observacoes,
					})
			);*/
      setSeveritySnackbar("success");
      setMensagemSnackbar("Pedido(s) enviado(s)!");
      handleSnackBar();

      Cookies.remove("pedidos");
      navigate("/finalizado");
    },
  });
  const handleSnackBar = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 6000);
  };
  return (
    <>
      <Header />
      <form onSubmit={formik.handleSubmit}>
        <Container sx={{ marginTop: "1rem" }}>
          <Grid container spacing={2} marginBottom="1rem" marginTop="5rem">
            <Container component={Paper}>
              <Typography variant="h4">Forma de entrega</Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="teleentrega"
                  name="radio-buttons-group"
                  onChange={() => toggleForm()}
                >
                  <FormControlLabel
                    value="teleentrega"
                    control={<Radio />}
                    label="Tele-entrega R$10.00"
                  />
                  <FormControlLabel
                    value="loja"
                    control={<Radio />}
                    label="Retirar na loja"
                  />
                </RadioGroup>
              </FormControl>
            </Container>
          </Grid>
        </Container>

        <Container sx={{ marginTop: "1rem" }}>
          <Grid container spacing={2} marginBottom="1rem" marginTop="2rem">
            <Container component={Paper}>
              <Grid marginTop="1rem" marginBottom="1rem" item xs={4}>
                <Typography variant="h4">Preencha as informações</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="nomeCliente"
                    name="nomeCliente"
                    label="Nome completo"
                    value={formik.values.nomeCliente}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nomeCliente &&
                      Boolean(formik.errors.nomeCliente)
                    }
                    helperText={
                      formik.touched.nomeCliente && formik.errors.nomeCliente
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="numeroTelefone"
                    name="numeroTelefone"
                    label="Nº telefone"
                    value={formik.values.numeroTelefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.numeroTelefone &&
                      Boolean(formik.errors.numeroTelefone)
                    }
                    helperText={
                      formik.touched.numeroTelefone &&
                      formik.errors.numeroTelefone
                    }
                  />
                </Grid>
              </Grid>
              {!showAddressAndPayment && (
                <Grid container spacing={2} marginTop="0.1rem">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      id="rua"
                      name="rua"
                      label="Rua"
                      value={formik.values.rua}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.rua && Boolean(formik.errors.rua)}
                      helperText={formik.touched.rua && formik.errors.rua}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      id="numero"
                      name="numero"
                      label="Número"
                      value={formik.values.numero}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.numero && Boolean(formik.errors.numero)
                      }
                      helperText={formik.touched.numero && formik.errors.numero}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    marginTop="0.1rem"
                    marginLeft="0.1rem"
                  >
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                        value={formik.values.bairro}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.bairro && Boolean(formik.errors.bairro)
                        }
                        helperText={
                          formik.touched.bairro && formik.errors.bairro
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        id="pontoReferencia"
                        name="pontoReferencia"
                        label="Ponto de referência"
                        value={formik.values.pontoReferencia}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.pontoReferencia &&
                          Boolean(formik.errors.pontoReferencia)
                        }
                        helperText={
                          formik.touched.pontoReferencia &&
                          formik.errors.pontoReferencia
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid container marginButtom="1rem" marginTop="1rem"></Grid>
            </Container>
          </Grid>
        </Container>
        <Container sx={{ marginTop: "1rem" }}>
          <Grid container spacing={2} marginBottom="1rem" marginTop="2rem">
            <Container component={Paper}>
              <Typography variant="h4" marginTop="1rem">
                Forma de Pagamento
              </Typography>
              <Grid marginTop="1rem" marginBottom="1rem" item xs={4}></Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} marginBottom="2rem">
                  <FormControl
                    fullWidth
                    error={
                      formik.touched.formaPagamento &&
                      Boolean(formik.errors.formaPagamento)
                    }
                  >
                    <InputLabel>Forma de Pagamento:</InputLabel>
                    <Select
                      id="formaPagamento"
                      name="formaPagamento"
                      label="forma pagamento"
                      value={formik.values.formaPagamento}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem key="pix" value="Pix">
                        Pix
                      </MenuItem>
                      <MenuItem key="dinheiro" value="Dinheiro">
                        Dinheiro
                      </MenuItem>
                      <MenuItem key="cartaocred" value="Cartao de credito">
                        Cartão de crédito (outros)
                      </MenuItem>
                      <MenuItem key="cartaobanr" value="Banrisul CartaoCidadao">
                        Banrisul/Cartão Cidadão
                      </MenuItem>
                    </Select>
                    {formik.touched.formaPagamento &&
                    formik.errors.formaPagamento ? (
                      <FormHelperText>
                        {formik.errors.formaPagamento}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} marginBottom="2rem">
                  <TextField
                    fullWidth
                    id="observacoes"
                    name="observacoes"
                    multiline
                    rows={5}
                    label="Insira observações sobre o pedido"
                    value={formik.values.observacoes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Container>
        <Grid container marginLeft="4rem">
          <Typography sx={{ float: "left" }}>
            Total:
            <Typography item xs={1} fontWeight="bold">
              R${calcularTotal()}
            </Typography>
          </Typography>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          style={{ marginTop: "1rem", marginLeft: "4rem" }} // Definindo margem superior e esquerda
        >
          Finalizar pedido
        </Button>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={severitySnackbar}
        >
          {mensagemSnackbar}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Endereco;
